// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login._index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login._index.tsx");
  import.meta.hot.lastModified = "1739185044129.939";
}
// REMIX HMR END

import { json, redirect } from "@remix-run/node";
import { Form, useActionData, useNavigation } from "@remix-run/react";
import axios from "axios";
import { Button } from "@nextui-org/react";
import CustomInput from "~/components/ui/inputs/input";
import { useEffect, useState } from "react";
import { commitFlashSession, getFlashSession } from "~/flash-session";
const AdminLogin = () => {
  _s();
  const navigation = useNavigation();
  const actionData = useActionData();
  const [actionErrors, setActionErrors] = useState();
  useEffect(() => {
    setActionErrors(actionData);
  }, [actionData]);
  return <Form className='flex flex-col gap-8 w-full md:w-[80%]' id='login-form' method='POST' replace={true}>
            <CustomInput label='Phone Number' name='phone' isRequired={true} isInvalid={actionErrors && actionErrors?.errors?.find(error => error.field === "phone") ? true : false} errorMessage={actionErrors && actionErrors?.errors?.find(error => error.field === "phone")?.message} onValueChange={() => setActionErrors(undefined)} />
            <Button variant='solid' color='primary' className='font-montserrat font-semibold text-lg' isLoading={navigation.state === "loading" || navigation.state === "submitting"} type='submit' form='login-form'>
                Send
            </Button>
        </Form>;
};
_s(AdminLogin, "1duNQtBmXDsGa8ThFGqtBcAv6zg=", false, function () {
  return [useNavigation, useActionData];
});
_c = AdminLogin;
export default AdminLogin;
export const action = async ({
  request
}) => {
  const baseUrl = process.env.API_BASE_URL;
  const flashSession = await getFlashSession(request.headers.get("Cookie"));
  const formData = await request.formData();
  const phone = formData.get("phone");
  try {
    const response = await axios.post(`${baseUrl}/login`, {
      phone
    });
    console.log(response.data);
    if (response.data.status === "success" && response.data.data.smsResponse.status === "SUCCESS") {
      flashSession.flash("alert", {
        status: "success",
        title: "Success!",
        message: response.data.message + " successfully. Kindly check your phone to verify"
      });
      return redirect("/login/verify-otp", {
        headers: {
          "Set-Cookie": await commitFlashSession(flashSession)
        }
      });
    }
    if (response.data.status === "success" && response.data.data.smsResponse.status !== "SUCCESS") {
      flashSession.flash("alert", {
        status: "error",
        title: "Error!",
        message: "OTP could not be sent. Please try again or contact IT..."
      });
      return redirect("/login", {
        headers: {
          "Set-Cookie": await commitFlashSession(flashSession)
        }
      });
    }
    if (response.data.status === "error") {
      flashSession.flash("alert", {
        status: "error",
        title: "Error!",
        message: response.data.message
      });
      return json(response.data, {
        headers: {
          "Set-Cookie": await commitFlashSession(flashSession)
        }
      });
    }
  } catch (error) {
    console.log(error);
    flashSession.flash("alert", {
      status: "error",
      title: "Error!",
      message: "An unexpected error occurred. " + error.message
    });
    return redirect("/login", {
      headers: {
        "Set-Cookie": await commitFlashSession(flashSession)
      }
    });
  }
};
export const meta = () => {
  return [{
    title: "Login | Adamus Med Treatment"
  }, {
    name: "description",
    content: "."
  }, {
    name: "author",
    content: "KwaminaWhyte"
  }, {
    name: "og:title",
    content: "Adamus Med Treatment"
  }, {
    name: "og:description",
    content: ""
  }, {
    name: "og:image",
    content: "https://res.cloudinary.com/app-deity/image/upload/v1701282976/qfdbysyu0wqeugtcq9wq.jpg"
  }];
};
var _c;
$RefreshReg$(_c, "AdminLogin");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;